@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.cdnfonts.com/css/butler");

body {
  margin: 0;
  font-family: outfit, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f2ee;
}

.appbar {
    background: transparent !important;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s ease-in !important;
    box-shadow: none !important;
    /* color: #1d68d8c6; */
}

.appbar_bg {
  
    height: 100px;
    backdrop-filter: blur(30px);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s ease-in !important;
}
.card-border {
  border: "0.5px solid #dfdeda ";
}
.div {
  box-sizing: border-box !important;
}
.text-container {
  opacity: 0;
  transform: translateX(-100%);
  transition:
    transform 1s ease-out,
    opacity 1s ease-out;
}

.text-container.animate {
  opacity: 1;
  transform: translateX(0);
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;
}

.hide_scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiModal-root:focus {
  outline: none !important;
}

.MuiModal-root .MuiBox-root:focus {
  outline: none !important;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
}



/* ADS */

.slide-in-from-right {
  transform: translateX(100%);
  animation: slide-in 0.5s forwards ease-in-out;
}

.slide-out-to-left {
  transform: translateX(0);
  animation: slide-out 0.5s forwards ease-in-out;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}


.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


/* Chat scrollbar */
.chat_scrollbar::-webkit-scrollbar{
width:4px
}
.chat_scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.chat_scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
/* Message Loader */

.loader {
  width: 20px;
  height: 20px;
  border: 2px dotted #a1bbe3c6;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 2px dotted #1d68d8c6;
  border-style: solid solid dotted;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}